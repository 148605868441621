/* import __COLOCATED_TEMPLATE__ from './pass-to-team.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class PassToTeam extends Component {
  @service appService;

  get app() {
    return this.appService.app;
  }
}
