/* import __COLOCATED_TEMPLATE__ from './template-action-preview-modal.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { post } from 'embercom/lib/ajax';
import type RouterService from '@ember/routing/router-service';
import { tracked } from 'tracked-built-ins';
import { capitalize } from '@ember/string';
import type Action from 'embercom/models/workflow-connector/action';

interface Args {
  onModalClose: () => void;
  selectedActionId: string;
  afterCreateAction?: () => void;
  startPage?: string;
}

export default class TemplateActionPreviewModal extends Component<Args> {
  @service declare store: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare notificationsService: $TSFixMe;
  @service declare redirectService: $TSFixMe;
  @service attributeService: $TSFixMe;
  @service declare contentImportService: $TSFixMe;

  @tracked user = null;

  @tracked declare workflowAction: $TSFixMe;
  @tracked messengerLang = this.app.locale;
  @tracked isCreatingAction = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.fetchAiContentAndContentIngestionState).perform();
    taskFor(this.fetchWorkflowAction).perform();
  }

  @dropTask
  *fetchAiContentAndContentIngestionState() {
    yield Promise.all([
      this.contentImportService.fetchAiContentLibrarySummary(),
      this.contentImportService.fetchContentIngestionState(),
    ]);
  }

  @dropTask
  *fetchWorkflowAction(): TaskGenerator<void> {
    try {
      this.workflowAction = yield this.store.findRecord(
        'workflow-connector/action',
        this.args.selectedActionId,
      );
    } catch (exception) {
      this.notificationsService.notifyError(
        this.intl.t('workflow-connector.actions.templates.fetching-error'),
      );
    }
  }

  get ruleset() {
    return this.workflowAction.ruleset;
  }

  get predicatesForAudiencePreview() {
    return this.ruleset.predicateGroup.predicates.map((predicate: any) => {
      return capitalize(predicate.descriptionWithName);
    });
  }

  get userUnprotectedFromSpoofing() {
    return !this.workflowAction.userProtectedFromSpoofing;
  }

  @action
  setMessengerLang(newLang: string) {
    this.messengerLang = newLang;
  }

  @action
  async createDraftAction(section: string) {
    let actionState = 'draft';

    if (this.isStripeActionTemplate && !this.workflowAction.tokenId) {
      return this.redirectService.redirect(this.authentication_url({ actionState }));
    }

    this.isCreatingAction = true;

    await taskFor(this.createActionFromTemplate)
      .perform(actionState)
      .then((createdAction: any) => {
        this.router.transitionTo(
          'apps.app.settings.app-settings.custom-actions.custom-action',
          createdAction.id,
          { queryParams: { section } },
        );
        this.isCreatingAction = false;
        this.args.onModalClose();
      });
  }

  @action
  async createLiveAction() {
    let actionState = 'live';

    if (this.isStripeActionTemplate && !this.workflowAction.tokenId) {
      return this.redirectService.redirect(this.authentication_url({ actionState }));
    }

    this.isCreatingAction = true;

    await taskFor(this.createActionFromTemplate)
      .perform(actionState)
      .then(() => {
        this.notificationsService.notifyConfirmation(
          this.intl.t('workflow-connector.actions.templates.created-live-action-success', {
            actionName: this.workflowAction.name,
          }),
        );
        Promise.resolve(this.args.afterCreateAction?.()).then(() => {
          this.isCreatingAction = false;
          this.args.onModalClose();
        });
      });
  }

  get app() {
    return this.appService.app;
  }

  get isStripeActionTemplate() {
    return this.workflowAction.isTemplate && this.workflowAction.appPackageCode === 'stripe';
  }

  authentication_url({ actionState }: { actionState?: string }) {
    let params = {
      start: this.args.startPage,
      action_state: actionState,
      template_action_id: this.args.selectedActionId,
    };

    let query = Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    return `apps/${this.app.id}/app_packages/stripe_integration/authentication_url?${query}`;
  }

  @dropTask
  *createActionFromTemplate(state: string): TaskGenerator<void> {
    try {
      let params = {
        app_id: this.app.id,
        template_action_id: this.args.selectedActionId,
        state,
      };
      let createdAction = yield post(
        `/ember/workflow_connector/actions/create_action_from_template`,
        params,
      );
      this.trackAnalyticsEvent(`created-${state}-from-template`, 'custom-action', createdAction.id);
      return createdAction;
    } catch (exception) {
      this.notificationsService.notifyError(
        this.intl.t('workflow-connector.actions.templates.error'),
      );
    }
  }

  trackAnalyticsEvent(action: string, object: string, actionId = null) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      actionId,
    });
  }

  @action
  sendSuggestedQuestionToFinPreview(message: string) {
    let finPreview: any = window.document.querySelector(
      '#template-action-preview-messenger #hosted-messenger-fin-demo-preview',
    );
    finPreview?.contentWindow?.postMessage(
      JSON.stringify({ type: 'intercom-send-message', message }),
      '*',
    );
    this.trackAnalyticsEvent('send-suggested-question-to-fin', 'custom-action');
  }

  get templateSuggestedQuestions() {
    return this.workflowAction.suggestedQuestions;
  }

  get isLoading() {
    return (
      taskFor(this.fetchWorkflowAction).isRunning ||
      taskFor(this.createActionFromTemplate).isRunning ||
      this.isCreatingAction
    );
  }

  get audienceAttributes() {
    return this.workflowAction.ruleset;
  }
  get workflowActionType() {
    if (this.workflowAction.httpMethod === 'get') {
      return this.intl.t('workflow-connector.actions.templates.get-data');
    }
    return this.intl.t('workflow-connector.actions.templates.update-data');
  }

  get templateActionIcon() {
    return this.workflowAction.isRecommendedAction ? 'webhook' : 'new';
  }

  roundConversationUsage = (action: Action) => {
    if (!action.conversationUsage) {
      return 0;
    }
    if (action.conversationUsage < 0.5) {
      return 0.5;
    }

    return Math.ceil(action.conversationUsage);
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::TemplateActionPreviewModal': typeof TemplateActionPreviewModal;
    'workflow-connector/template-action-preview-modal': typeof TemplateActionPreviewModal;
  }
}
