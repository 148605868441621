/* import __COLOCATED_TEMPLATE__ from './test-result.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import type Action from 'embercom/models/workflow-connector/action';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import { task } from 'ember-concurrency-decorators';
import { post } from 'embercom/lib/ajax';
import { isBlank } from '@ember/utils';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

interface Args {
  workflowAction: Action;
  templatedParameters: any;
  clearTemplatedParameter: () => void;
  saveTemplatedParameter: (requestParameter: any, requestValue: any) => void;
  nextSection: (sectionId: string) => void;
}

export default class TestResult extends Component<Args> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare attributeService: any;
  @service intercomEventService: any;
  @service declare store: Store;

  @tracked declare bannerText: string;
  @tracked declare bannerType: 'confirmation' | 'error';
  @tracked declare testResponse: any;
  @tracked declare responseBodyToDisplay: any;
  @tracked disableTestButton = false;
  @tracked attributeNames: any = {};

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.getAttributeNames();
    this.requestParameters();
    this.getValidTest();
    this.setDisableTestButton();
  }

  getAttributeNames() {
    this.allAttributes.forEach(
      (attribute: any) => (this.attributeNames[attribute.identifier] = attribute.name),
    );
    this.resolver.eventAttributes.forEach(
      (attribute: any) => (this.attributeNames[attribute.templatable_identifier] = attribute.name),
    );
  }

  requestParameters() {
    let currentParameters = this.args.templatedParameters;
    this.args.clearTemplatedParameter();

    Array.from(this.args.workflowAction.attributes).map((attribute) =>
      this.args.saveTemplatedParameter(attribute[1], currentParameters[attribute[1]] || ''),
    );
  }

  getValidTest() {
    if (this.args.workflowAction.testIsValid) {
      this.responseBodyToDisplay = JSON.stringify(
        this.args.workflowAction.testResult.actionResponse.body,
        null,
        2,
      );
      this.bannerText = this.args.workflowAction.testResult.actionResponse.status.toString();
      this.bannerText += ' ';
      this.bannerText +=
        this.args.workflowAction.testResult.actionResponse.returnCode.toUpperCase();
      this.bannerType = 'confirmation';
    }
  }

  setDisableTestButton() {
    this.disableTestButton = Object.values(this.args.templatedParameters).some(isBlank);
  }

  validateRequestBody() {
    if (['get', 'delete'].includes(this.args.workflowAction.httpMethod)) {
      this.args.workflowAction.body = null;
    }
  }

  get resolver(): AttributeInfoResolver {
    return new AttributeInfoResolver({
      attributes: this.attributeService.allAttributes,
      includeAppAttributes: true,
    });
  }

  get allAttributes() {
    return [...this.attributeService.allAttributes, ...this.resolver.manualAppAttributes];
  }

  get showTestValuesHeaders() {
    return Object.keys(this.args.templatedParameters).length;
  }

  @action
  nextSection() {
    this.args.nextSection('response_section');
  }

  @action
  setParameterValue(requestParameter: any, event: any) {
    this.args.saveTemplatedParameter(requestParameter, event.target.value);
    this.setDisableTestButton();
  }

  @task({ drop: true })
  *testRequest(): TaskGenerator<void> {
    this.validateRequestBody();
    let params = {
      app_id: this.appService.app.id,
      workflow_action: this.args.workflowAction,
      templated_values: this.args.templatedParameters,
    };

    try {
      let testResponse = yield post(
        `/ember/workflow_connector/actions/${this.args.workflowAction.id}/test`,
        params,
      );
      this.testResponse = testResponse.test_result;
      this.responseBodyToDisplay = this.getResponseBody(this.testResponse.action_response);

      let normalizedTestResult = (
        this.store.normalize('workflow-connector/test-result', this.testResponse) as any
      ).data.attributes;
      let testResult = this.store.createFragment(
        'workflow-connector/test-result',
        normalizedTestResult,
      );
      this.args.workflowAction.testResult = testResult;

      let responseFields = testResponse.response_fields.map((responseField: any) => {
        let normalizedResponseFields = (
          this.store.normalize('workflow-connector/response-field', responseField) as any
        ).data.attributes;

        return this.store.createFragment(
          'workflow-connector/response-field',
          normalizedResponseFields,
        );
      });

      this.args.workflowAction.responseFields = responseFields;

      this._setBannerParams();
    } catch (exception) {
      this._setBannerOnException(exception);
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'test_request',
      action_id: this.args.workflowAction.id,
    });
  }

  get isTestResultRunning() {
    return taskFor(this.testRequest).isRunning;
  }

  _setBannerParams() {
    this.bannerText = '';
    if (this.testResponse.action_response.success) {
      this.bannerType = 'confirmation';
      this.bannerText = this.testResponse.action_response.status;
      if (this.testResponse.action_response.return_code.toLowerCase() === 'ok') {
        this.bannerText += ` ${this.testResponse.action_response.return_code.toUpperCase()}`;
      } else {
        this.bannerText += ` ${this.testResponse.action_response.return_code}`;
      }
      this.setTestedRequest();
    } else {
      this.bannerType = 'error';
      this.bannerText += this._getErrorMessage(this.testResponse.action_response);
    }
  }

  _getErrorMessage(action_response: any) {
    return (
      action_response.human_readable_error ||
      action_response.body?.errors ||
      this.intl.t('workflow-connector.builder.body.testing.error-message')
    );
  }

  _setBannerOnException(exception: any) {
    this.bannerType = 'error';
    this.bannerText = exception.errorThrown;
  }

  getResponseBody(action_response: any) {
    return action_response.body !== null
      ? JSON.stringify(this.testResponse.action_response.body, null, 2)
      : action_response.raw_body;
  }

  setTestedRequest() {
    this.args.workflowAction.testResult.testedAction = {};
    this.args.workflowAction.testResult.testedAction.httpMethod =
      this.args.workflowAction.httpMethod;
    this.args.workflowAction.testResult.testedAction.url = this.args.workflowAction.url;
    this.args.workflowAction.testResult.testedAction.headers = this.args.workflowAction.headers;
    this.args.workflowAction.testResult.testedAction.body = this.args.workflowAction.body;
    this.args.workflowAction.testResult.testedAction.tokenId = this.args.workflowAction.tokenId;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'WorkflowConnector::Builder::Body::Sections::Testing::TestResult': typeof TestResult;
    'workflow-connector/builder/body/sections/testing/test-result': typeof TestResult;
  }
}
